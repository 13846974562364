import { ISchedule } from '../../../types/schedule'
import { booleanToDayNameMap } from '../WeeklyDayPicker/WeeklyDayPicker'

export const generateScheduleSummary = (schedule: ISchedule) => {
    const {
        days_of_week: daysOfWeek = {},
        period,
        start_time = [],
        is_active: isActive,
    } = schedule

    const dateFrom = period.from
    const dateFromSummary = dateFrom ? `from ${dateFrom}` : ''

    const dateTo = period.to
    const dateToSummary = dateTo ? `to ${dateTo}` : ''

    const days = Object.entries(daysOfWeek)
        .filter(([_, v]) => Boolean(v))
        // @ts-ignore
        .map(([k]) => booleanToDayNameMap[k])
    const daysSummary = days.length ? `every ${days.join(', ')}` : ''

    const times = start_time.filter((time) => Boolean(time))
    const timesSummary = times?.length ? `at ${times.join(', ')}` : ''

    const isNoSummary =
        !dateFromSummary && !dateToSummary && !daysSummary && !timesSummary
    const inactiveSummary = 'Schedule is not active'
    const summary = `The app is scheduled to run ${dateFromSummary} ${dateToSummary} ${daysSummary} ${timesSummary}`

    return !isActive ? inactiveSummary : isNoSummary ? '' : summary
}
