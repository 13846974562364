import React, { FC } from 'react'
import { componentConstructor } from '../../../../GUIConstructor/control'
import { getRangeSchema } from '../../Utils'

export const AdjustableRange: FC<any> = ({
    data,
    dataSchema = {},
    name = '',
    childrenPath,
    dataProp,
    blockState,
    levelDeep = 2,
    element_id,
    inCard,
    isRuleActive,
    pathWithElementID,
    builderType,
}) => (
    <>
        {componentConstructor(
            getRangeSchema(dataSchema, data),
            Object.keys(data || {}).length ? data : dataProp,
            name,
            'block',
            '',
            childrenPath,
            undefined,
            !blockState,
            levelDeep + 1,
            element_id,
            inCard,
            isRuleActive,
            pathWithElementID,
            builderType
        )}
    </>
)
