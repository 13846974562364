import styles from './WeeklyDayPicker.module.scss'
import { withTooltip } from 't4b-core-frontend'
import { DaysPicked } from '../../../types/schedule'
import { FormattedMessage } from 'react-intl'

export const daysOfWeek = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
] as const

const daysPickedMap: Record<typeof daysOfWeek[number], keyof DaysPicked> = {
    Mon: 'is_monday',
    Tue: 'is_tuesday',
    Wed: 'is_wednesday',
    Thu: 'is_thursday',
    Fri: 'is_friday',
    Sat: 'is_saturday',
    Sun: 'is_sunday',
}

export const booleanToDayNameMap = {
    is_monday: 'Monday',
    is_tuesday: 'Tuesday',
    is_wednesday: 'Wednesday',
    is_thursday: 'Thursday',
    is_friday: 'Friday',
    is_saturday: 'Saturday',
    is_sunday: 'Sunday',
}

interface WeeklyDayPickerProps {
    daysPicked: DaysPicked
    onChange: (value: Partial<DaysPicked>) => void
}

export const WeeklyDayPicker = (props: WeeklyDayPickerProps) => {
    const { daysPicked, onChange } = props

    const allDaysValues = Object.values(daysPicked)
    const isAllDaysFalse = allDaysValues.every((day) => !day)

    return (
        <div className={styles.wrapper}>
            <div className={styles.days}>
                {daysOfWeek.map((day) => (
                    <DayOfWeekBtn
                        key={day}
                        day={day}
                        isOn={daysPicked[daysPickedMap[day]]}
                        onDayClick={onChange}
                    />
                ))}
            </div>
            {isAllDaysFalse && (
                <span className={styles.errMsg}>
                    <FormattedMessage id={'errors.validation.oneDayRequired'} />
                </span>
            )}
        </div>
    )
}

interface DayOfWeekBtnProps {
    day: typeof daysOfWeek[number]
    isOn: boolean
    onDayClick: (value: Partial<DaysPicked>) => void
}

const DayOfWeekBtn = (props: DayOfWeekBtnProps) => {
    const { day, isOn, onDayClick } = props
    const boolDayName = daysPickedMap[day]

    const handleClick = () => {
        onDayClick({ [boolDayName]: !isOn })
    }

    const btn = (
        <button
            onClick={handleClick}
            className={`btn-clean ${styles.dayBtn} ${!isOn && styles.off}`}
        >
            {day}
        </button>
    )
    const tooltip = `Press to ${isOn ? 'disable' : 'enable'}`

    return withTooltip(btn, tooltip, day)
}
