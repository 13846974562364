export enum ConfigurationsActionType {
  FETCH_CONFIGURATION = 'FETCH_CONFIGURATION',
  FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS',
  FETCH_CONFIGURATION_ERROR = 'FETCH_CONFIGURATION_ERROR',
  CONFIGURATION_RESET = 'CONFIGURATION_RESET',
  CONFIGURATION_RESET_SUCCESS = 'CONFIGURATION_RESET_SUCCESS',
  APPLY_CONFIGURATION = 'APPLY_CONFIGURATION',
  APPLY_CONFIGURATION_SUCCESS = 'APPLY_CONFIGURATION_SUCCESS',
  SET_INVALID_CONFIGURATION = 'SET_INVALID_CONFIGURATION',
  SET_VALID_CONFIGURATION = 'SET_VALID_CONFIGURATION',
  BLOCK_DICTIONARY_ADD = 'BLOCK_DICTIONARY_ADD',
  BLOCK_DICTIONARY_DELETE = 'BLOCK_DICTIONARY_DELETE',
  BLOCK_DICTIONARY_NEW_KEY = 'BLOCK_DICTIONARY_NEW_KEY',
  BLOCK_DICTIONARY_EDIT_DICT_VALUE = 'BLOCK_DICTIONARY_EDIT_DICT_VALUE',
  FLAG_MODIFY = 'FLAG_MODIFY',
  ENUM_MODIFY = 'ENUM_MODIFY',
  INPUT_MODIFY = 'INPUT_MODIFY',
  DATE_TIME_MODIFY = 'DATE_TIME_MODIFY',
  MULTI_SELECT_MODIFY = 'MULTI_SELECT_MODIFY',
  CHECK_INVALID_TABS = 'CHECK_INVALID_TABS',
  BLOCK_ARRAY_TABLE_IMPORT_CSV_DATA = 'BLOCK_ARRAY_TABLE_IMPORT_CSV_DATA',
  BLOCK_ARRAY_TABLE_REPLACE = 'BLOCK_ARRAY_TABLE_REPLACE',
  BLOCK_ARRAY_TABLE_ADD_ROW_TOP = 'BLOCK_ARRAY_TABLE_ADD_ROW_TOP',
  BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM = 'BLOCK_ARRAY_TABLE_ADD_ROW_BOTTOM',
  BLOCK_ARRAY_CARD_ADD_TO_BEGINNING = 'BLOCK_ARRAY_CARD_ADD_TO_BEGINNING',
  BLOCK_ARRAY_CARD_ADD_TO_END = 'BLOCK_ARRAY_CARD_ADD_TO_END',
  BLOCK_ARRAY_CARD_DUPLICATE = 'BLOCK_ARRAY_CARD_DUPLICATE',
  BLOCK_ARRAY_CARD_ADD_100 = 'BLOCK_ARRAY_CARD_ADD_100',
  BLOCK_ARRAY_DND = 'BLOCK_ARRAY_DND',
  BLOCK_ARRAY_TABLE_MODIFY = 'BLOCK_ARRAY_TABLE_MODIFY',
  BLOCK_ARRAY_DELETE_ITEM = 'BLOCK_ARRAY_DELETE_ITEM',
  EMPTY_CONFIG_INIT = 'EMPTY_CONFIG_INIT',
  INIT_EMPTY_DATA = 'INIT_EMPTY_DATA',
  DISABLE_BLOCK = 'DISABLE_BLOCK',
  ENABLE_BLOCK = 'ENABLE_BLOCK',
  BLOCK_ARRAY_ITEM_DELETE = 'BLOCK_ARRAY_ITEM_DELETE',
  START_TESTS = 'START_TESTS',
  END_TESTS = 'END_TESTS',
  SET_NEW_RULE_NAME = 'SET_NEW_RULE_NAME',
  CHANGE_RULE_STATE = 'CHANGE_RULE_STATE',
  REFRESH_RULES = 'REFRESH_RULES',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
}

export interface IConfigState {
  productsConfigurations: any
  productsConfigurationsDefault: any
  unsaved: any
  loading: boolean
  fetchingError: boolean
  invalidFields: any
  isChecked: any
  isTesting: boolean
}
