export enum BlockArrayTypes {
  CARD = 'card',
  TABLE = 'table',
  MULTI_SELECT = 'multiSelect',
  TABLE_VIEW = 'tableView'
}

export interface IBlockArray {
  config: any
  schema: any
  title: string
  description?: string
  dataPath?: string[]
  wrapper?: string
  levelDeep?: number
  isViewOnly?: boolean
  element_id?: string
  inCard?: boolean
  isRuleActive?: boolean
  pathWithElementID?: string[]
  isParentDisabled?: boolean
}

export interface IBlockArrayTable {
  config: any
  schema: any
  title: string
  className?: string
  description?: string
  dataPath: string[]
  levelDeep?: number
  isViewOnly?: boolean
  element_id?: string
  inCard?: boolean
  isRuleActive?: boolean
  pathWithElementID?: string[]
  isParentDisabled?: boolean
}

export interface IBlockArrayTableRow {
  data: any
  index: number
  focusIndex: number
  onItemFocus: (index: number) => void
  schema?: any
  dataPath?: string[]
}

export interface ICardsContainerProps {
  config?: any
  dataPath?: string[]
  title?: string
  schema: any
  wrapper?: string
  levelDeep?: number
  isViewOnly?: boolean
  element_id?: string
  inCard?: boolean
  isRuleActive?: boolean
  pathWithElementID?: string[]
  isParentDisabled?: boolean
}

export interface ICardProps {
  config?: any
  setCardToDeleteElementID?: React.Dispatch<React.SetStateAction<null | string>>
  index: number
  position?: number
  name?: any
  title?: string
  schema: any
  dataPath?: any
  levelDeep?: number
  rulesBlocks: any[]
  isOrderUserDefined: boolean | undefined
  element_id?: string
  inCard?: boolean
  isParentCardActive?: boolean
  focusIndex: number
  isNewCardAdded: boolean
  inRuleModal?: boolean
  pathWithElementID?: string[]
}

export interface IMultiSelectProps {
  data: any
  dataSchema: any
  name: string
  wrapper?: string
  dataPath?: string[]
  levelDeep?: number
  isViewOnly?: boolean
  element_id?: string
  inCard?: boolean
  isRuleActive?: boolean
  pathWithElementID?: string[]
}

export interface IMultiSelectAdvanced {
  data: any
  name: string
  path: string[]
  schema: any
}

export interface IMultiSelectAdvancedRow {
  item: any
  path: string[]
  state: any
  setState: any
  schema: any
  name: string
}
