import axios from 'axios'
import { Dispatch } from 'redux'
import { ConfigurationsActionType } from '../../types/configurations'
import { alertErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { getHeaders } from './http'
import { AppDispatch } from '../../types/store'

const API = `/api/${apiVersion}/schema_and_configuration`

export const deleteBlockArrayItem =
    (payload: { path: string[]; arrOfItems: any[]; errorKeyName: string }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ConfigurationsActionType.BLOCK_ARRAY_ITEM_DELETE,
            payload,
        })
    }

export const duplicateArrayItem =
    (payload: { path: string[], data: { index: number, item: any } }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ConfigurationsActionType.BLOCK_ARRAY_CARD_DUPLICATE,
            payload,
        })
    }

export const disableBlock =
    (payload: { path: string[]; name: string; errorKeyName: string }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ConfigurationsActionType.DISABLE_BLOCK,
            payload,
        })
    }

export const enableBlock =
    (payload: { path: string[]; name: string; data: any }) =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ConfigurationsActionType.ENABLE_BLOCK,
            payload,
        })
    }

export const checkUnsavedChanges =
    (guid: string): any =>
    (dispatch: AppDispatch) => {
        // use setTimeout to not block ui (requestIdleCallback is not supported in safari)
        setTimeout(() =>
            dispatch({
                type: ConfigurationsActionType.UNSAVED_CHANGES,
                payload: { guid },
            })
        )
    }

export const checkInvalidTabs =
    (payload: { guid: string; data: number }): any =>
    (dispatch: AppDispatch) => {
        dispatch({
            type: ConfigurationsActionType.CHECK_INVALID_TABS,
            payload,
        })
    }

export const setConfigurationInValid = (
    guid: string,
    tabName: string,
    errorKeyName?: string
): any => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ConfigurationsActionType.SET_INVALID_CONFIGURATION,
            payload: { guid, tabName, errorKeyName },
        })
    }
}

export const setConfigurationValid = (
    guid: string,
    tabName: string,
    errorKeyName?: string
): any => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ConfigurationsActionType.SET_VALID_CONFIGURATION,
            payload: { guid, tabName, errorKeyName },
        })
    }
}

// ============ action-creators ============ //

export const fetchProduct = (guid: string, forceUpdate?: boolean): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch({ type: ConfigurationsActionType.FETCH_CONFIGURATION })
            const { data } = await axios.get(`${API}/${guid}`, {
                headers: getHeaders(),
            })
            dispatch({
                type: ConfigurationsActionType.FETCH_CONFIGURATION_SUCCESS,
                payload: { guid, data, forceUpdate },
            })
        } catch (e: any) {
            alertErrorMessage(e)

            dispatch({
                type: ConfigurationsActionType.FETCH_CONFIGURATION_ERROR,
                payload: 'Fetching error',
            })
        }
    }
}

export const resetProduct = (guid: string): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch({ type: ConfigurationsActionType.CONFIGURATION_RESET })
            const { data } = await axios.get(`${API}/${guid}`, {
                headers: getHeaders(),
            })

            dispatch({
                type: ConfigurationsActionType.CONFIGURATION_RESET_SUCCESS,
                payload: { guid, data },
            })

            dispatch(checkUnsavedChanges(guid))
        } catch (e: any) {
            alertErrorMessage(e)

            dispatch({
                type: ConfigurationsActionType.FETCH_CONFIGURATION_ERROR,
                payload: 'Fetching error',
            })
        }
    }
}

export const applyProductChanges = (
    guid: string,
    configuration: any,
    isTesting?: boolean
): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            await axios.post(
                `${API}/${guid}`,
                { ...configuration },
                { headers: getHeaders() }
            )

            throwSuccessMessage(`Success!`)

            const { data } = await axios.get(`${API}/${guid}`, {
                headers: getHeaders(),
            })

            dispatch({
                type: ConfigurationsActionType.APPLY_CONFIGURATION_SUCCESS,
                payload: { configuration: data, guid },
            })

            dispatch(fetchProduct(guid))
            dispatch(checkUnsavedChanges(guid))
        } catch (e: any) {
            if (isTesting) {
                sessionStorage.removeItem('isTesting')
            }

            alertErrorMessage(e)
        }
    }
}
