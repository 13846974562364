import React from 'react'
import logs from '../../../assets/icons/logs.svg'
import { BDropdown } from '../BDropdown/BDropdown'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { Period } from '../../modals/LogsModal/utils'
import { prepareProductLogs } from '../../../store/actions/logsActions'

interface LogsDropdownProps {
    className?: string
    guid: string
}

export const LogsDropdown = (props: LogsDropdownProps) => {
    const { guid, className } = props
    const dispatch = useAppDispatch()

    const handleLiveLogs = () => {
        window.open(`/${guid}/logs`, '_blank', )
    }
    const handleDownloadLogs = () => {
        dispatch(
            showModal(ModalTypes.LOGS_MODAL, {
                guid,
                productLogs: true,
                dispatcher: (period: Period) =>
                    dispatch(prepareProductLogs(period, guid)),
            })
        )
    }

    const liveLogs = {
        name: 'buttons.liveLogs',
        icon: faBook,
        action: handleLiveLogs,
    }
    const downloadLogs = {
        name: 'buttons.downloadLogs',
        icon: faDownload,
        action: handleDownloadLogs,
    }

    const logsItems = [liveLogs, downloadLogs]

    return (
        <BDropdown
            className={`rounded-blue-btn ${className}`}
            tooltip="Logs"
            dropdownItems={logsItems}
            dropdownToggle={
                <img
                    alt=""
                    className="pb-1"
                    src={logs}
                />
            }
        />
    )
}
