import { BDropdown } from '../BDropdown/BDropdown'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectProductSystemActions } from '../../../store/selectors/systemActionsSelectors'
import { AppDropdownItem } from '../AppDropdown/AppDropdown'
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis'
import { RightBarActionType } from '../../../types/rightbar'
import { CoreSettings } from '../../CoreSettings/CoreSettings'
import React from 'react'
import { User } from '../../../entity/User'

interface DropdownMoreProps {
    className?: string
    guid: string
}

export const DropdownMore = (props: DropdownMoreProps) => {
    const { guid, className } = props
    const dispatch = useAppDispatch()
    const user = new User()

    const productSystemActions =
        useTypedSelector(selectProductSystemActions(guid)) ?? {}

    const {
        get_configuration_file: hasGetConfigFile,
        set_configuration_file: hasSetConfigFile,
        get_general_settings: hasGetGeneralSettings,
    } = productSystemActions

    const handleUploadConfig = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        dispatch(
            showModal(ModalTypes.UPLOAD_CONFIGS_MODAL, {
                guid,
            })
        )
    }

    const handleDownloadConfig = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        dispatch(
            showModal(ModalTypes.CONFIGS_MODAL, {
                guid,
            })
        )
    }

    const handleOpenGeneralSettings = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        dispatch({
            type: RightBarActionType.SHOW_RIGHT_BAR,
            payload: {
                rightBarItem: <CoreSettings guid={guid} />,
            },
        })
    }

    const uploadConfig: AppDropdownItem = {
        name: 'buttons.uploadConfig',
        icon: faFileUpload,
        action: handleUploadConfig,
        disabled: !hasSetConfigFile,
    }
    const downloadConfig: AppDropdownItem = {
        name: 'buttons.downloadConfig',
        icon: faFileDownload,
        action: handleDownloadConfig,
        disabled: !hasGetConfigFile,
    }
    const generalSettings: AppDropdownItem = {
        name: 'buttons.generalSettings',
        icon: faGear,
        action: handleOpenGeneralSettings,
        disabled: !hasGetGeneralSettings,
    }

    const dropdownItems = user.isFullRightsManagerOrAbove()
        ? [uploadConfig, downloadConfig, generalSettings]
        : [generalSettings]

    return (
        <BDropdown
            hasHr={user.isFullRightsManagerOrAbove()}
            className={`rounded-blue-btn ${className}`}
            tooltip="More"
            dropdownItems={dropdownItems}
            icon={faEllipsis}
        />
    )
}
