import styles from '../ScheduleItem.module.scss'
import { WeeklyDayPicker } from '../../WeeklyDayPicker/WeeklyDayPicker'
import React from 'react'
import { DaysPicked, ISchedule } from '../../../../types/schedule'
import { PeriodPicker } from '../../PeriodPicker/PeriodPicker'
import { TimePicker } from '../../TimePicker/TimePicker'
import {
    changeScheduleDateFrom,
    changeScheduleDateTo,
    changeScheduleDays,
    changeScheduleTimes,
} from '../../../../store/actions/schedulesActions'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { transformDate } from '../../../models/DateTime/utils'
import { TimeFormats } from '../../../models/DateTime/types'
import { getDatePickerValues } from '../../../inputs/DateTimePicker/getDatePickerValues'

interface ScheduleItemBodyProps {
    guid: string
    schedule: ISchedule
    index: number
}

export const ScheduleItemBody = (props: ScheduleItemBodyProps) => {
    const { guid, index, schedule } = props
    const dispatch = useAppDispatch()

    const dateFrom = getDatePickerValues(
        TimeFormats.DATE,
        { from: schedule?.period.from },
        'from'
    ).selected

    const dateTo = getDatePickerValues(
        TimeFormats.DATE,
        { to: schedule?.period.to },
        'to'
    ).selected

    const handleDayOfWeekChange = (value: Partial<DaysPicked>) => {
        const days = { ...schedule.days_of_week, ...value }
        dispatch(changeScheduleDays({ guid, index, days }))
    }

    const handleDateFromChange = (date: Date) => {
        const dateFromFormatted =
            transformDate(TimeFormats.DATE, date, TimeFormats.DATE) ?? ''
        dispatch(
            changeScheduleDateFrom({ guid, index, dateFrom: dateFromFormatted })
        )
    }

    const handleDateToChange = (date: Date | null) => {
        const dateToFormatted = date ? transformDate(TimeFormats.DATE, date, TimeFormats.DATE) : null
        dispatch(changeScheduleDateTo({ guid, index, dateTo: dateToFormatted }))
    }

    const handleTimeAdd = () => {
        dispatch(
            changeScheduleTimes({
                guid,
                index,
                times: [...schedule?.start_time, null],
            })
        )
    }

    const handleTimeChange = (timeIndex: number, newTime: string | null) => {
        const newTimes = schedule?.start_time?.toSpliced(timeIndex, 1, newTime)
        dispatch(changeScheduleTimes({ guid, index, times: newTimes }))
    }

    const handleTimeDelete = (timeIndex: number) => {
            const newTimes = schedule?.start_time?.toSpliced(timeIndex, 1)
            dispatch(changeScheduleTimes({ guid, index, times: newTimes }))
    }

    const isTimeDeleteDisabled = schedule?.start_time?.length === 1
    
    const timePickers = schedule?.start_time?.map((time, timeIndex) => {
        const timeToDate = getDatePickerValues(
            TimeFormats.HOURS_MINUTES_SECONDS,
            { time: time },
            'time'
        ).selected

        return (
            <TimePicker
                time={timeToDate}
                index={timeIndex}
                onTimeChange={handleTimeChange}
                onTimeDelete={handleTimeDelete}
                isDeleteDisabled={isTimeDeleteDisabled}
                className={styles.picker}
            />
        )
    })

    const disabled = schedule.is_active ? '' : 'viewOnly opacity-50'

    return (
        <div
            className={`px-4 d-flex flex-column gap-4 ${styles.scheduleItemBody} ${disabled}`}
        >
            <div className="d-flex gap-4">
                <PeriodPicker
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onDateFromChange={handleDateFromChange}
                    onDateToChange={handleDateToChange}
                    className={styles.picker}
                />
                <WeeklyDayPicker
                    daysPicked={schedule.days_of_week}
                    onChange={handleDayOfWeekChange}
                />
            </div>
            <div className="d-flex gap-4 flex-wrap">
                {timePickers}
                <button
                    className={`btn-clean d-flex gap-2 align-items-center pe-1 btn ${styles.addTimeBtn}`}
                    onClick={handleTimeAdd}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add time</span>
                </button>
            </div>
        </div>
    )
}
