import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar'

interface PeriodPickerProps {
    dateFrom: Date | null | undefined
    dateTo: Date | null | undefined
    onDateFromChange: (date: Date) => void
    onDateToChange: (date: Date | null) => void
    className?: string
}

export const PeriodPicker = (props: PeriodPickerProps) => {
    const { dateFrom, dateTo, onDateFromChange, onDateToChange, className } =
        props

    if (dateFrom && dateTo && dateFrom.getTime() > dateTo.getTime()) {
        onDateToChange(null)
    }

    const handleDateFromChange = (date: Date) => {
        onDateFromChange(date)
    }
    const handleDateTo = (date: Date) => {
        onDateToChange(date)
    }

    return (
        <div className="d-flex gap-2">
            <DatePicker
                className={`w-90 ${className}`}
                enableTabLoop={false}
                selectsStart
                selected={dateFrom}
                onChange={handleDateFromChange}
                startDate={dateFrom}
                endDate={dateTo}
                dateFormat="yyyy-MM-dd"
                placeholderText="From"
                showIcon
                icon={
                    <FontAwesomeIcon
                        icon={faCalendar}
                        color="grey"
                    />
                }
            />
            <strong style={{ alignSelf: 'center' }}>-</strong>
            <DatePicker
                className={`w-90 ${className}`}
                enableTabLoop={false}
                selected={dateTo}
                onChange={handleDateTo}
                selectsEnd
                startDate={dateFrom}
                endDate={dateTo}
                minDate={dateFrom}
                dateFormat="yyyy-MM-dd"
                placeholderText="To"
                showIcon
                icon={
                    <FontAwesomeIcon
                        icon={faCalendar}
                        color="grey"
                    />
                }
            />
        </div>
    )
}
