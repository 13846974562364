import { ButtonWithTooltip } from '../ButtonWithTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar'
import { RightBarActionType } from '../../../types/rightbar'
import React from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { ScheduleBar } from '../../ScheduleBar/ScheduleBar'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectOneSystemAction } from '../../../store/selectors/systemActionsSelectors'

interface ScheduleBtnProps {
    guid: string
}

export const ScheduleBtn = (props: ScheduleBtnProps) => {
    const { guid } = props
    const dispatch = useAppDispatch()

    const hasGetJobSchedule = useTypedSelector(selectOneSystemAction(guid, 'get_job_schedule'))

    const handleShowScheduler = () => {
        dispatch({
            type: RightBarActionType.SCHEDULE_BAR,
            payload: {
                rightBarItem: (
                    <ScheduleBar guid={guid} />
                ),
            },
        })
    }

    return (
        <ButtonWithTooltip tooltip="Launch schedule">
            <button
                className="rounded-blue-btn"
                onClick={handleShowScheduler}
                disabled={!hasGetJobSchedule}
            >
                <FontAwesomeIcon
                    icon={faCalendar}
                    color="white"
                />
            </button>
        </ButtonWithTooltip>
    )
}
