import * as yup from 'yup'

export const getCoreSettingsValidationSchema = () => {
    return {
        restart_attempts: yup
            .string()
            .matches(/^(0|[1-9]\d*|)$/gi)
            .nullable(),
        restart_delay_sec: yup
            .string()
            .matches(/^(0|[1-9]\d*|)$/gi)
            .nullable(),
        product_status_on_start: yup.string().required(),
    }
}
