import React, { FC, useEffect } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import ProductButtonsPanel from '../buttons/ProductButtonsPanel/ProductButtonsPanel'
import Loader from '../Loader/Loader'
import { Card, Nav, Tab } from 'react-bootstrap'
import { Switch } from 'react-router-dom'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { IProductContainer } from './types'
import { IProduct, ProductActionType } from '../../types/product'
import { hideModal, showModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import { useDispatch } from 'react-redux'
import ProductDisableModal from '../modals/ProductDisableModal'
import { ITabData } from '../GUIConstructor/types'
import { ProductStatus } from '../../entity/Product'
import { actualSchemaVersion } from '../../versions'
import { checkVersion, checkVersionsMatch } from '../../versions/utils'
import style from './Styles/index.module.scss'
import { convertToSentenceCase } from '../../utils/convertToSentenceCase'
import { FormattedMessage } from 'react-intl'
import {
    applyProductChanges,
    checkInvalidTabs,
    resetProduct,
} from '../../store/actions/configurationActions'
import { alertDetailedErrorMessage } from '../../utils/Errors'

const ProductContainer: FC<IProductContainer> = ({
    routesData,
    productPath,
    appName,
    guid,
}) => {
    const dispatch = useDispatch()
    const { products } = useTypedSelector((state) => state.products)
    const {
        productsConfigurations,
        loading,
        isChecked,
        invalidFields,
        unsaved,
    } = useTypedSelector((state) => state.configurations)
    const activeTab = sessionStorage.getItem(guid) || 0
    const currentProduct = products.find((el: IProduct) => el.guid === guid)
    const isActive = currentProduct?.status !== ProductStatus.INACTIVE
    const schemaVersion = productsConfigurations[guid]?.schema_version
    const isVersionValid = checkVersion(schemaVersion)
    const isSchemasVersionsMatch = checkVersionsMatch(
        schemaVersion,
        actualSchemaVersion
    )

    const productInvalidationObj = invalidFields[guid]
    const productInvalidationKeys = Object.keys(productInvalidationObj ?? {})

    const getValidationResult = () => {
        for (let key of productInvalidationKeys) {
            const tabInvalidationObj = productInvalidationObj[key]
            const tabInvalidationKeys = Object.keys(tabInvalidationObj ?? {})
            const hasInvalidFieldsInTab = !!tabInvalidationKeys.length
            if (hasInvalidFieldsInTab) {
                return { isProductConfigInvalid: true, configName: key }
            }
        }
        return { isProductConfigInvalid: false, configName: null }
    }

    const { isProductConfigInvalid, configName } = getValidationResult()

    useEffect(() => {
        dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
    }, []) // eslint-disable-line

    useEffect(() => {
        if (!isActive) {
            dispatch(
                showModal(ModalTypes.PRODUCT_UNAVAILABLE_MODAL, {
                    title: 'product.unavailable',
                })
            )
        }
        if (
            (!isSchemasVersionsMatch.major || !isVersionValid) &&
            productsConfigurations[guid]
        ) {
            dispatch(
                showModal(ModalTypes.PRODUCT_UNAVAILABLE_MODAL, {
                    title: 'schema.versionNotMatchTitle',
                    message: 'schema.versionNotMatchMessage',
                    values: {
                        configurationSchemaVersion: String(schemaVersion),
                        actualSchemaVersion,
                    },
                })
            )
        }
        if (isSchemasVersionsMatch.major && isVersionValid) {
            dispatch(hideModal())
        }
        if (!isSchemasVersionsMatch.minor && isVersionValid) {
            if (!sessionStorage.getItem(`${guid}-productNameLoaded`)) {
                dispatch(
                    showModal(ModalTypes.INFORM_MODAL, {
                        message: 'schema.minorVersionNotMatchMessage',
                        values: {
                            configurationSchemaVersion: String(schemaVersion),
                            actualSchemaVersion,
                        },
                    })
                )
                sessionStorage.setItem(`${guid}-productNameLoaded`, 'true')
            }
        }
    }, [currentProduct?.status, schemaVersion]) // eslint-disable-line

    const setNabNumber = (num: number): void => {
        sessionStorage.setItem(guid, num.toString())
    }

    const handleOnReset = () => {
        dispatch(
            showModal(ModalTypes.MAIN_MODAL, {
                body: `Reset all changes for ${appName}?`,
                onSubmit: () => dispatch(resetProduct(guid)),
            })
        )
    }

    const handleOnApply = () => {
        console.log(productsConfigurations[guid])

        dispatch(checkInvalidTabs({ guid, data: 1 }))

        if (isProductConfigInvalid) {
            alertDetailedErrorMessage(
                `Failed to apply configuration. Configuration contains error(s). Check tab ${String(
                    configName
                ).toUpperCase()}`
            )
        }
        if (!isProductConfigInvalid) {
            dispatch(
                showModal(ModalTypes.MAIN_MODAL, {
                    body: `Apply all changes for ${appName}?`,
                    onSubmit: () =>
                        dispatch(
                            applyProductChanges(
                                guid,
                                productsConfigurations[guid]
                            )
                        ),
                })
            )
            dispatch(checkInvalidTabs({ guid, data: 0 }))
        }
    }

    if (loading) return <Loader />

    return (
        <div className={`mx-5 mt-5 flex-1 ${style.mainContainer}`}>
            <ProductButtonsPanel
                title={appName}
                appName={appName}
                guid={guid}
                currentProduct={currentProduct}
            />
            <Tab.Container
                id="left-tabs"
                defaultActiveKey={activeTab}
            >
                <div className={style.headerContainer}>
                    <Card.Header className={`nav-dark ${style.header}`}>
                        <Nav
                            variant="tabs"
                            defaultActiveKey={productPath}
                        >
                            {routesData.map((navItem: ITabData, i: number) => (
                                <Nav.Item key={navItem.path}>
                                    <Nav.Link
                                        eventKey={i}
                                        onClick={() => setNabNumber(i)}
                                    >
                                        <div className={`text-capital`}>
                                            {convertToSentenceCase(
                                                navItem.name
                                            )}
                                            {Object.keys(
                                                productInvalidationObj?.[
                                                    navItem.keyName
                                                ] || {}
                                            ).length !== 0 &&
                                                isChecked[guid] && (
                                                    <span className="text-danger ms-2 p-0">
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faExclamationCircle
                                                            }
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Card.Header>
                    <div className="d-flex gap-2 mb-1">
                        <button
                            className="app-btn-apply app-btn-main"
                            type="button"
                            disabled={!unsaved[guid || '']}
                            onClick={handleOnReset}
                        >
                            <FormattedMessage id="reset" />
                        </button>
                        <button
                            className="app-btn-reset app-btn-main app-btn-apply me-3"
                            type="submit"
                            onClick={handleOnApply}
                        >
                            <FormattedMessage id="apply" />
                        </button>
                    </div>
                </div>
                <Card className={style.card}>
                    <Card.Body className="vh-full-scroll pt-0 p-0 pb-1">
                        <OverlayScrollbarsComponent className="h-100 ps-4 pe-4">
                            <React.Suspense
                                fallback={
                                    <div className="vh-full d-flex justify-content-center">
                                        <Loader />
                                    </div>
                                }
                            >
                                <Switch>
                                    <>
                                        <Tab.Content>
                                            {routesData.map(
                                                (
                                                    route: ITabData,
                                                    i: number
                                                ) => (
                                                    <Tab.Pane
                                                        key={route.path}
                                                        eventKey={i}
                                                    >
                                                        {route.component}
                                                    </Tab.Pane>
                                                )
                                            )}
                                        </Tab.Content>
                                    </>
                                </Switch>
                            </React.Suspense>
                        </OverlayScrollbarsComponent>
                    </Card.Body>
                </Card>
            </Tab.Container>
            <ProductDisableModal />
        </div>
    )
}

export default ProductContainer
