import { LogsActions } from '../types/logs'
import { HubConnection } from '@microsoft/signalr'
import { AppDispatch } from '../types/store'
import { logOnSignalRMethodTrigger } from './lib/logOnSignalRMethodTrigger'
import { SignalRMethods } from './lib/SignalRMethods'

export const receiveLiveLogs = (
    connection: HubConnection,
    dispatch: AppDispatch
) => {
    connection.on(SignalRMethods.LOG_BATCH, (logsBatch, productGuid) => {
        logOnSignalRMethodTrigger(
            SignalRMethods.LOG_BATCH,
            logsBatch,
            productGuid
        )
        if (productGuid && logsBatch && logsBatch['log_batch']) {
            dispatch({
                type: LogsActions.NEW_LIVE_LOGS,
                payload: { productGuid, liveLogs: logsBatch['log_batch'] },
            })
        }
    })
}