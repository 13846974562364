export enum ActionsActionTypes {
    GET_SYSTEM_ACTIONS_SUCCESS = 'GET_SYSTEM_ACTIONS_SUCCESS',
    GET_CUSTOM_ACTIONS_SUCCESS = 'GET_CUSTOM_ACTIONS_SUCCESS',
    GET_ACTIONS_HISTORY = 'GET_ACTION_HISTORY',
    RESET_ACTION_PARAMETERS = 'RESET_ACTION_PARAMETERS',
    MODIFY_ACTION_PARAMETER = 'MODIFY_ACTION_PARAMETER',
    SET_ACTION_INVALID = 'SET_ACTION_INVALID',
    SET_ACTION_VALID = 'SET_ACTION_VALID',
    ADD_CARD_ITEM_TO_ARRAY_END = 'ADD_CARD_ITEM_TO_ARRAY_END',
    ADD_CARD_ITEM_TO_ARRAY_BEGINNING = 'ADD_CARD_ITEM_TO_ARRAY_BEGINNING',
    DELETE_ITEM_FROM_ARRAY = 'DELETE_ITEM_FROM_ARRAY',
}

export interface ICustomAction {
    description: string
    request_type: 'request' | 'custom_request'
    execution_mode: 'sync_mode' | 'async_mode'
    access_level: 'access_full' | 'access_restricted'
    request_schema: Record<string, unknown>
}
export type ICustomActionsDict = Record<string /* Action name */, ICustomAction>


export interface ISystemAction extends Omit<ICustomAction, 'description' | 'request_schema'> {
    request_type: 'request' | 'custom_request'
    execution_mode: 'sync_mode' | 'async_mode'
    access_level: 'access_full' | 'access_restricted'
}
export type SystemActionType =
    | 'get_logs'
    | 'get_configuration_file'
    | 'set_configuration_file'
    | 'change_activity_status'
    | 'get_general_settings'
    | 'set_general_settings'
    | 'get_job_schedule'
    | 'set_job_schedule'


export type ISystemActionsDict = Record<SystemActionType, ISystemAction>

export interface IActionHistory {
    action_id: string
    action_name: string
    product_id: string
    status: 'ongoing' | 'success' | 'failure'
    issue_time: string // 'YYYY-MM-DDThh:mm:ss'
    client_payload: Record<string, unknown>
    result_type: 'none' | 'file'
    result?: {
        filename: string,
        mime_type: string
    }
    error?: string | undefined
}

export interface IActionsState {
    systemActions: Record<string /* guid */, ISystemActionsDict>
    customActions: Record<string /* guid */, ICustomActionsDict>
    actionsParameters: Record<string /* guid */, Record<string, any> | undefined>
    actionsHistory: Record<string /* guid */, IActionHistory[]>
    invalidActions: Record<string /* guid */, Record<string, any>>
    isLoading: boolean
    error: string
}
